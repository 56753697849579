import { Anchor, FlexView, Text, Icon } from '@components/Common';
import languages from '@locales/languages';
import { Colors } from '@styles/system';

import ModalM from './ModalM';

interface ModalProps {
  language: 'ko' | 'en';
  closePortal: () => void;
}

export default ({ language, closePortal }: ModalProps) => {
  const TEXTS = languages[language].modal;

  const wrapperCSS = {
    backgroundColor: Colors.gray700,
    borderRadius: `8px`,
    width: `152px`,
    minHeight: `60px`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  };

  return (
    <ModalM closePortal={closePortal} color="#141414" close>
      <FlexView
        css={{
          paddingTop: `40px`,
          overflowY: `auto`,
          scrollbarWidth: `none`,
          '::-webkit-scrollbar': { display: `none` },
        }}
        items="center"
        fill
      >
        <Icon color={Colors.primary} name="logo" size={24} />

        <FlexView css={{ margin: `24px 0 40px 0` }}>
          <Text color={Colors.secondary70} center multiLine>
            {TEXTS.downloadGuideM}
          </Text>
        </FlexView>

        <FlexView content="center" gap={24} row>
          <Anchor
            css={wrapperCSS}
            href="https://apps.apple.com/us/app/blockchat/id1635256021"
            referrerPolicy="no-referrer"
            target="_blank"
          >
            <Icon color={Colors.white} name="appStore" size={24} />
          </Anchor>

          <Anchor
            css={wrapperCSS}
            href="https://play.google.com/store/apps/details?id=com.blockchainlabs.android"
            referrerPolicy="no-referrer"
            target="_blank"
          >
            <Icon color={Colors.white} name="googlePlay" size={20} />
          </Anchor>
        </FlexView>

        <FlexView
          css={{
            padding: `24px 0 40px 0`,
            margin: `32px 0 40px 0`,
            borderTop: `1px solid ${Colors.secondary20}`,
            borderBottom: `1px solid ${Colors.secondary20}`,
          }}
          gap={24}
          items="center"
        >
          <Text color={Colors.secondary} medium>
            {TEXTS.notice}
          </Text>

          <FlexView gap={20} items="center">
            <FlexView css={{ width: `100%` }} gap={12} row>
              <Text color={Colors.secondary70} css={{ lineHeight: `150%` }}>
                1.
              </Text>
              <Text color={Colors.secondary70} fill multiLine small>
                {TEXTS.notice1}
              </Text>
            </FlexView>

            {/* <FlexView css={{ width: `100%` }} gap={12} row>
              <Text color={Colors.secondary70} css={{ lineHeight: `150%` }}>
                2.
              </Text>
              <Text color={Colors.secondary70} multiLine small>
                {TEXTS.notice2}
              </Text>
            </FlexView> */}
          </FlexView>
        </FlexView>

        <FlexView gap={4} items="center">
          <Text color={Colors.secondary70} center multiLine>
            {TEXTS.adviceM}
          </Text>
          <Text color={Colors.primary} center>
            {TEXTS.email}
          </Text>
        </FlexView>
      </FlexView>
    </ModalM>
  );
};
