import { FlexView } from '@components/Common';
import {
  About,
  Contact,
  HowToUse,
  Features,
  IntroDesktop,
  IntroMobile,
  FAQ,
  Prologue,
} from '@components/Section';
import { useResponsive } from '@utils/hooks';
import { useRef } from 'react';

export default () => {
  const deviceType = useResponsive();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const Intro = {
    P: <IntroDesktop lang="en" />,
    T: <IntroMobile lang="en" />,
    M: <IntroMobile lang="en" />,
  };

  return (
    <FlexView
      ref={wrapperRef}
      css={{
        marginTop: deviceType === `P` ? `100px` : `60px`,
      }}
      gap={16}
    >
      {Intro[deviceType]}
      <Prologue lang="en" />
      <About lang="en" />
      <HowToUse lang="en" />
      <Features lang="en" />
      <FAQ lang="en" />
      <Contact lang="en" />
    </FlexView>
  );
};
