import { FlexView, Text, Video } from '@components/Common';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useLocation } from 'react-router-dom';

interface PhoneCardProps {
  src: string;
  title: string;
  subtitle?: string;
  content: string;
}

export default ({ src, title, subtitle, content }: PhoneCardProps) => {
  const location = useLocation();
  const deviceType = useResponsive();
  const language = location.pathname === `/` ? `ko` : `en`;

  return (
    <FlexView
      content="between"
      css={{ maxWidth: `1040px`, width: `100%` }}
      gap={deviceType === `M` ? 40 : 80}
      items="center"
      row={deviceType === `P`}
    >
      <FlexView>
        <Text
          color={Colors.primary}
          css={{ textAlign: deviceType === `P` ? `start` : `center` }}
          xLarge={deviceType === `T`}
          xxLarge={deviceType === `P`}
          medium
        >
          {subtitle}
        </Text>

        <Text
          center={deviceType !== `P`}
          color={Colors.white}
          css={{
            margin: deviceType === `M` ? `8px 0 24px 0` : `16px 0 40px 0`,
            fontSize: deviceType === `M` ? `26px` : `46px`,
            lineHeight: `130%`,
          }}
          bold
          multiLine
        >
          {title}
        </Text>

        <Text
          center={deviceType !== `P`}
          color={Colors.secondary70}
          css={{
            lineHeight: `160%`,
            letterSpacing: language === `en` ? `-0.5px` : `0`,
          }}
          xLarge={deviceType !== `M`}
          medium
          multiLine
        >
          {content}
        </Text>
      </FlexView>

      <FlexView
        css={{
          width: `375px`,
          height: `758px`,
          ...(deviceType !== `P` && { width: `280px`, height: `566px` }),
        }}
      >
        <Video
          css={{ maxWidth: `100%`, objectFit: `cover` }}
          src={src}
          autoPlay
          loop
          muted
          playsInline
        />
      </FlexView>
    </FlexView>
  );
};
