import { Button, FlexView, Icon, Link, Text } from '@components/Common';
import languages from '@locales/languages';
import { Colors } from '@styles/system';
import { useState, useEffect, useRef, CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';

const headerCSS: CSSProperties = {
  position: `fixed`,
  top: 0,
  left: 0,
  right: 0,
  height: `100px`,
  padding: `40px 80px 20px 80px`,
  backgroundColor: Colors.black,
  zIndex: 1000,
};

interface HeaderProps {
  language: 'ko' | 'en';
}

export default ({ language }: HeaderProps) => {
  const location = useLocation();

  const TEXTS = languages[language].menu;
  const hideRef = useRef<HTMLDivElement>(null);
  const [showLogo, setShowLogo] = useState<boolean>(true);
  const [selectedMenu, setSelectedMenu] = useState<string>(`intro`);

  const ID_LIST = [
    `intro`,
    `prologue`,
    `about`,
    `howtouse`,
    `features`,
    `faq`,
    `contact`,
  ];
  const MENU_LIST = [
    TEXTS.about,
    TEXTS.howToUse,
    TEXTS.features,
    TEXTS.faq,
    TEXTS.contact,
  ];

  const goTop = () => {
    window.scrollTo({ top: 0, behavior: `smooth` });
  };

  const selectMenu = (id: number) => {
    setSelectedMenu(ID_LIST[id]);
    window.scrollTo({
      top: (document.getElementById(ID_LIST[id])?.offsetTop as number) - 10,
      behavior: `smooth`,
    });
  };

  const revealHeader = () => {
    setShowLogo(true);
    if (hideRef.current) {
      hideRef.current.style.opacity = `1`;
    }
  };

  useEffect(() => {
    const onScroll = () => {
      const nowPosition = window.scrollY;
      const section1 = document.getElementById(`prologue`)?.offsetTop as number;
      const section2 = document.getElementById(`about`)?.offsetTop as number;
      const section3 = document.getElementById(`howtouse`)?.offsetTop as number;
      const section4 = document.getElementById(`features`)?.offsetTop as number;
      const section5 = document.getElementById(`faq`)?.offsetTop as number;
      const section6 = document.getElementById(`contact`)?.offsetTop as number;

      if (nowPosition < section1 - 10) {
        setSelectedMenu(`intro`);
        setShowLogo(false);
      } else if (nowPosition < section2 - 10) {
        setSelectedMenu(`prologue`);
        revealHeader();
      } else if (nowPosition < section3 - 10) {
        setSelectedMenu(`about`);
        revealHeader();
      } else if (nowPosition < section4 - 10) {
        setSelectedMenu(`howtouse`);
        revealHeader();
      } else if (nowPosition < section5 - 10) {
        setSelectedMenu(`features`);
        revealHeader();
      } else if (nowPosition < section6 - 10) {
        setSelectedMenu(`faq`);
        revealHeader();
      } else if (nowPosition >= section6 - 10) {
        setSelectedMenu(`contact`);
        revealHeader();
      }
    };

    setTimeout(() => {
      if (hideRef.current === null) return;
      hideRef.current.style.opacity = `1`;
    }, 7500);

    window.addEventListener(`scroll`, onScroll);

    return () => {
      window.removeEventListener(`scroll`, onScroll);
    };
  }, []);

  return (
    <header style={headerCSS}>
      <FlexView
        ref={hideRef}
        content={showLogo ? `between` : `end`}
        css={{ transition: `opacity 1.5s`, opacity: 0 }}
        items="center"
        row
      >
        <FlexView>
          {showLogo && (
            <Button
              aria-label="home"
              css={{ width: `197.14px`, height: `24px` }}
              onClick={goTop}
            >
              <Text color="transparent" css={{ width: `90%` }}>
                Header Logo
              </Text>
              <Icon
                color={Colors.primary}
                css={{ position: `absolute` }}
                name="logo"
                size={24}
              />
            </Button>
          )}
        </FlexView>

        <FlexView gap={40} row>
          <FlexView gap={10} row>
            {MENU_LIST?.map((item, index) => (
              <Button
                key={item}
                arial-label={item}
                css={{ width: `106px`, height: `40px` }}
                onClick={() => selectMenu(index + 2)}
              >
                <Text
                  color={
                    ID_LIST.indexOf(selectedMenu) === index + 2
                      ? Colors.primary
                      : Colors.secondary
                  }
                  css={{ transition: `color 0.5s` }}
                  semiBold
                >
                  {item}
                </Text>
              </Button>
            ))}
          </FlexView>

          <FlexView items="center" row>
            <Link
              aria-label="KOR"
              css={{
                width: `64px`,
                lineHeight: `36px`,
                textAlign: `center`,
                color:
                  location.pathname === `/`
                    ? Colors.secondary
                    : Colors.secondary30,
              }}
              to="/"
            >
              KOR
            </Link>

            <Link
              aria-label="ENG"
              css={{
                width: `64px`,
                lineHeight: `36px`,
                textAlign: `center`,
                color:
                  location.pathname === `/en`
                    ? Colors.secondary
                    : Colors.secondary30,
              }}
              to="/en"
            >
              ENG
            </Link>
          </FlexView>
        </FlexView>
      </FlexView>
    </header>
  );
};
