export default {
  logo: `logo`,
  header: `header`,
  menu: {
    about: `About`,
    howToUse: `How To Use`,
    features: `Features`,
    faq: `FAQ`,
    contact: `Contact Us`,
  },
  modal: {
    downloadGuide: `You can download the latest version of BlockChat from App Store or Google Play.`,
    downloadGuideM: `You can download the latest version of\nBlockChat from App Store or Google Play.`,
    notice: `Notice`,
    notice1: `File sharing feature is available on the latest version of BlockChat.`,
    // notice2: `Voice Calling feature is planned to become available in the first half of 2023.`,
    advice1: `If you are experiencing any difficulties or have ideas on`,
    advice2: `how to improve BlockChat, please send us an email at`,
    adviceM: `If you are experiencing any difficulties or\nhave ideas on how to improve BlockChat,\nplease send us an email at`,
    email: `contact@block-chat.net`,
  },
  intro: {
    subtitle1: `BlockChat is developed by Blockchain Labs - the developer and operator of`,
    subtitle1M: `BlockChat is developed by Blockchain Labs\n- the developer and operator of`,
    subtitle2: `the vaccine passport for 43 million users.`,
    download: `Download`,
  },
  prologue: {
    content1: `BlockChat uses the blockchain technology\nto enable the users to own and control\nthe privacy of their data, including their messages.`,
    content1M: `BlockChat uses the blockchain\ntechnology to enable the users\nto own and control the privacy of\ntheir data, including their messages.`,
    content2: `Moving past Web 2.0 normalized use of servers\nto centralize the storing of all data,\nwe are pioneering a true Web 3.0 that empowers\nindividuals to own the sovereignty of their data and monetize them.`,
    content2T: `Moving past Web 2.0 normalized use of\nservers to centralize the storing of all data,\nwe are pioneering a true Web 3.0 that empowers\nindividuals to own the sovereignty of their data\nand monetize them.`,
    content2M: `Moving past Web 2.0 normalized\na use of servers to centralize\nthe storing of all data, we are\npioneering a true Web 3.0 that\nempowers individuals to own\nthe sovereignty of their data and monetize them.`,
  },
  about: {
    title: `About BlockChat`,
    title1: `No personal data required.\nJust your blockchain ID.`,
    subtitle1: `Severless Blockchain Messenger`,
    content1: `Unlike other messenger's server-based log-in process,\nBlockChat uses the unique Blockchain ID of your device.\nBlockChat does not require your personal information.`,
    content1T: `Unlike other messenger's server-based\nlog-in process, BlockChat uses the unique\nBlockchain ID of your device.\nBlockChat does not require your personal information.`,
    content1M: `Unlike other messenger's server-based\nlog-in process, BlockChat uses the unique\nBlockchain ID of your device.\nBlockChat does not require your personal information.`,
    title2: `Say 'Good Bye' to unintended connections.`,
    subtitle2: `Cyber Crime Blockcing`,
    content2: `You must manually share the Connection Code to form BlockChat Connections,\nwhich prevents any unintended exposure to everyone in your contacts.\nBecause you already know who you are connecting with,\nanonymous cyber crimes can be prevented.`,
    content2T: `You must manually share the Connection Code to form BlockChat Connections,\nwhich prevents any unintended exposure to everyone in your contacts.\nBecause you already know who you are connecting with, anonymous cyber crimes can be prevented.`,
    content2M: `You must manually share the Connection Code\nto form BlockChat Connections,\nwhich prevents any unintended exposure\nto everyone in your contacts.\nBecause you already know who you are connecting with,\nanonymous cyber crimes can be prevented.`,
    title3: `Don’t worry about\nmalicious misuses of\nyour messages.`,
    subtitle3: `Privacy Protection`,
    content3: `On BlockChat, no one other than you and\nthe intended recipient can view the messages.\nIt is pointless for anyone to take screenshots of\nyour messages, because all messages (including\nthe ones sent by your friends) can be edited.`,
    content3M: `On BlockChat, no one other than you and\nthe intended recipient can view the messages.\nIt is pointless for anyone to take screenshots of\nyour messages, because all messages (including the ones sent by your friends) can be edited.`,
    title4: `Your calls,\nperfectly protected.`,
    subtitle4: `AI Voice Filtering`,
    content4: `You can also call your friends directly\nwithout a centralized server.\nUsing the AI voice filtering technology,\nyou can change your voices on your calls.\n\n(Will become available in the future)`,
    content4M: `You can also call your friends directly\nwithout a centralized server.\nUsing the AI voice filtering technology,\nyou can change your voices on your calls.\n\n(Will become available in the future)`,
  },
  howToUse: {
    title: `How To Use`,
    describe: `‘Connection’ refers to BlockChat’s direct communication channel between two or more parties, without involving a centralized server.`,
    describeM: `‘Connection’ refers to BlockChat’s\ndirect communication channel between\ntwo or more parties, without involving a centralized server.`,
    title1: `Creating Blockchain ID`,
    content1: `Click the ‘Create Blockchain ID’ button\nto generate a unique ID through\nan algorithm without a server.`,
    content1M: `Click the ‘Create Blockchain ID’ button to generate a unique ID through an algorithm without a server.`,
    title2: `Requesting a Connection`,
    content2: `After pressing ‘+’ button from the main screen,\npress ‘Create 1:1 Connection’ to send\na Connection Code to your friend.`,
    content2M: `After pressing ‘+’ button from the main screen, press ‘Create 1:1 Connection’ to send a Connection Code to your friend.`,
    title3: `Receiving a Connection`,
    content3: `After pressing ‘+’ button from the main screen,\npress ‘Enter Connection Code’ to get\nconnected with your friend.`,
    content3M: `After pressing ‘+’ button from the main screen, press ‘Enter Connection Code’ to get connected with your friend.`,
  },
  features: {
    title: `Features`,
    describe: `BlockChat's unique features completely protect your messages.`,
    describeM: `BlockChat's unique features\ncompletely protect your messages.`,
    title1: `Edit`,
    content1: `You can edit the recipients' names,\nthe contents of sent messages,\nand even the contents of received messages.`,
    title2: `Change Colors & Sizes`,
    content2: `You can freely change the color and size of sent and received messages.`,
    title3: `Change Display Alignment`,
    content3: `You can freely change the display location of sent and received messages.`,
    // future: `Voice Calling is planned to be available in the first half of 2023.`,
    // futureT: `Voice Calling is planned to be available\nin the first half of 2023.`,
    // futureM: `Voice Calling is planned to be available\nin the first half of 2023.`,
  },
  faq: {
    title: `FAQ`,
    term: `Terms`,
    usage: `App Usage`,
    privacy: `Privacy`,
    other: `Other`,
  },
  contact: {
    title: `Contact Us`,
    content: `Please send us an email for any questions or assistance regarding BlockChat`,
    contentM: `Please send us an email for any questions\nor assistance regarding BlockChat`,
  },
  footer: {
    company: `Blockchain Labs Inc.`,
    registrationNumber: `Corporate registration number: 243-87-00909`,
    ceo: `CEO: Bezalel Lim, Joe Park`,
    contact: `contact@block-chat.net`,
    addressKR: `10F, 311, Gangnam-daero, Seocho-gu, Seoul, South Korea`,
    addressUS: `535 Mission St 14th Floor, San Francisco, CA 94105`,
    copyright: `© 2024. Blockchain Labs Inc. All rights reserved.`,
  },
};