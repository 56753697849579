import { CSSObject, jsx } from '@emotion/react';
import { fontSizes, fontWeights } from '@styles/system';
import { HTMLAttributes, LabelHTMLAttributes } from 'react';

export type TextProps = (
  | HTMLAttributes<HTMLSpanElement>
  | LabelHTMLAttributes<HTMLLabelElement>
) & {
  xxSmall?: boolean;
  xSmall?: boolean;
  small?: boolean;
  large?: boolean;
  xLarge?: boolean;
  xxLarge?: boolean;
  light?: boolean;
  medium?: boolean;
  semiBold?: boolean;
  bold?: boolean;
  black?: boolean;
  color?: string;
  start?: boolean;
  center?: boolean;
  fill?: boolean;
  noDrag?: boolean;
  multiLine?: boolean;
};

export default ({
  xxSmall,
  xSmall,
  small,
  large,
  xLarge,
  xxLarge,
  light,
  medium,
  semiBold,
  bold,
  black,
  color,
  start,
  center,
  fill,
  noDrag,
  multiLine,
  ...props
}: TextProps) => {
  const css: CSSObject = {
    color: color || `black`,
    lineHeight: 1.25,
    userSelect: noDrag ? `none` : `auto`,
    ...fontSizes[
      (xxSmall && `xxSmall`) ||
        (xSmall && `xSmall`) ||
        (small && `small`) ||
        (large && `large`) ||
        (xLarge && `xLarge`) ||
        (xxLarge && `xxLarge`) ||
        `normal`
    ],
    ...fontWeights[
      (light && `light`) ||
        (medium && `medium`) ||
        (semiBold && `semiBold`) ||
        (bold && `bold`) ||
        (black && `black`) ||
        `regular`
    ],
    ...(start && { textAlign: `left` }),
    ...(center && { textAlign: `center` }),
    ...(fill && { flex: 1 }),
    ...(multiLine && { whiteSpace: `pre-wrap`, lineHeight: `150%` }),
  };

  return jsx(`span`, { css, ...props });
};
