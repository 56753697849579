export default {
  logo: `로고`,
  header: `헤더`,
  menu: {
    about: `블록챗 소개`,
    howToUse: `사용 방법`,
    features: `주요 기능`,
    faq: `FAQ`,
    contact: `문의`,
  },
  modal: {
    downloadGuide: `앱 스토어와 구글 플레이에서 최신 버전의 앱을 다운로드할 수 있습니다.`,
    downloadGuideM: `앱 스토어와 구글 플레이에서\n최신 버전의 앱을 다운로드할 수 있습니다.`,
    notice: `안내 사항`,
    notice1: `파일 전송 기능은 블록챗 최신 버전에서 이용할 수 있습니다.`,
    // notice2: `음성 통화 기능은 2023년 상반기 적용 예정입니다.`,
    advice1: `사용에 불편하신 점이나 개선 여부에 대한 여러분의 생각을 적극 수렴하고 있습니다.`,
    advice2: ` 으로 의견 남겨주시면 감사하겠습니다.`,
    adviceM: `사용에 불편하신 점이나 개선 여부에 대한\n여러분의 생각을 적극 수렴하고 있습니다.\n아래의 메일로 의견 남겨주시면 감사하겠습니다.`,
    email: `contact@block-chat.net`,
  },
  intro: {
    subtitle1: `블록챗은 4,300만 명이 사용하는 백신패스`,
    subtitle1M: `블록챗은 4,300만 명이 사용하는 백신패스`,
    subtitle2: `개발 운영사 블록체인랩스가 만든 메신저입니다`,
    download: `앱 다운로드`,
  },
  prologue: {
    content1: `블록챗은 블록체인 기술을 이용하여\n대화 내용을 비롯한 모든 개인 정보를\n개인이 직접 소유하고 활용하게 합니다.`,
    content1M: `블록챗은 블록체인 기술을 이용하여\n대화 내용을 비롯한 모든 개인 정보를\n개인이 직접 소유하고 활용하게 합니다.`,
    content2: `중앙 서버에 모든 것을 저장했던 웹 2.0 시대를 넘어\n개인이 자신의 정보 주권을 가지고 경제적 이익을 얻는\n진정한 웹 3.0 세상을 열어나가겠습니다.`,
    content2T: `중앙 서버에 모든 것을 저장했던 웹 2.0 시대를 넘어\n개인이 자신의 정보 주권을 가지고 경제적 이익을 얻는\n진정한 웹 3.0 세상을 열어나가겠습니다.`,
    content2M: `중앙 서버에 모든 것을 저장했던\n웹 2.0 시대를 넘어 개인이 자신의\n정보 주권을 가지고 경제적 이익을 얻는\n진정한 웹 3.0 세상을 열어나가겠습니다.`,
  },
  about: {
    title: `블록챗 소개`,
    title1: `개인 정보가 필요 없는\n블록체인 ID를 사용합니다`,
    subtitle1: `서버 없는 블록체인 메신저`,
    content1: `블록챗은 기존 메신저의 서버 기반 로그인 방식과 달리\n기기에서 생성되는 블록체인 ID를 이용하여\n당신의 개인 정보를 전혀 필요로 하지 않습니다`,
    content1T: `블록챗은 기존 메신저의 서버 기반 로그인 방식과 달리\n기기에서 생성되는 블록체인 ID를 이용하여\n당신의 개인 정보를 전혀 필요로 하지 않습니다`,
    content1M: `블록챗은 기존 메신저의\n서버 기반 로그인 방식과 달리\n기기에서 생성되는 블록체인 ID를 이용하여\n당신의 개인 정보를 전혀 필요로 하지 않습니다`,
    title2: `원하지 않는 사람과의\n연결을 막아줍니다`,
    subtitle2: `사이버 범죄 차단`,
    content2: `블록챗은 당신이 원하는 사람에게만 코드를 전송하여 연결되기 때문에\n내 연락처 및 ID를 아는 모든 사람에게 노출되는 것을 막아줍니다\n따라서 'N번방 사건'과 같은 익명의 사이버 범죄도 원천적으로 막을 수 있습니다`,
    content2T: `블록챗은 당신이 원하는 사람에게만 코드를 전송하여\n연결되기 때문에 내 연락처 및 ID를 아는 모든 사람에게\n노출되는 것을 막아줍니다\n따라서 'N번방 사건'과 같은 익명의 사이버 범죄도\n원천적으로 막을 수 있습니다`,
    content2M: `블록챗은 당신이 원하는 사람에게만\n코드를 전송하여 연결되기 때문에\n내 연락처 및 ID를 아는\n모든 사람에게 노출되는 것을 막아줍니다\n따라서 'N번방 사건'과 같은 익명의 사이버 범죄도\n원천적으로 막을 수 있습니다`,
    title3: `누구도 당신의 메시지를\n악의적으로 이용할 수 없습니다`,
    subtitle3: `개인 정보 보호`,
    content3: `블록챗은 제3자 누구도 당신의 대화를 볼 수 없고\n상대방의 메시지를 포함한 모든 것을 수정할 수 있어\n당신의 메시지를 악용하기 위해\n대화 내용을 캡처하거나 촬영하는 것이 소용없습니다`,
    content3M: `블록챗은 제3자 누구도 당신의 대화를 볼 수 없고\n상대방의 메시지를 포함한 모든 것을 수정할 수 있어\n당신의 메시지를 악용하기 위해\n대화 내용을 캡처하거나 촬영하는 것이 소용없습니다`,
    title4: `음성 통화 내용도\n완벽히 보호됩니다`,
    subtitle4: `AI 음성 필터링`,
    content4: `음성 통화도 서버 없이 원하는 상대와 직접할 수 있습니다\nAI 음성 필터링 기술을 활용하여 당신의 목소리를\n성우의 목소리로 바꿔 대화할 수 있습니다\n\n당신의 통화를 악용하기 위해 녹음하는 것이 소용없어\n보다 자유로운 커뮤니케이션을 가능하게 합니다\n\n(추후 업데이트 예정)`,
    content4M: `음성 통화도 서버 없이\n원하는 상대와 다이렉트로 할 수 있습니다\nAI 음성 필터링 기술을 활용하여 당신의 목소리를\n성우의 목소리로 바꿔 대화할 수 있습니다\n\n당신의 통화를 악용하기 위해\n녹음하는 것이 소용없어\n보다 자유로운 커뮤니케이션을 가능하게 합니다\n\n(추후 업데이트 예정)`,
  },
  howToUse: {
    title: `사용 방법`,
    describe: `커넥션은 서버 없이 상대방과 일대일로 직접 연결되는 블록챗의 대화공간입니다`,
    describeM: `커넥션은 서버 없이 상대방과 일대일로\n직접 연결되는 블록챗의 대화공간입니다`,
    title1: `블록체인 ID 만들기`,
    content1: `'블록체인 ID 만들기' 버튼을 누르면\n서버 없이 알고리즘을 통해 고유한 ID가 생성됩니다`,
    content1M: `'블록체인 ID 만들기' 버튼을 누르면\n서버 없이 알고리즘을 통해\n고유한 ID가 생성됩니다`,
    title2: `커넥션 요청 보내기`,
    content2: `앱 하단 '+' 버튼의 '1:1 커넥션 만들기'로\n대화하고 싶은 상대방에게\n생성된 커넥션 코드를 보냅니다`,
    content2M: `앱 하단 '+' 버튼의 '1:1 커넥션 만들기'로\n대화하고 싶은 상대방에게\n생성된 커넥션 코드를 보냅니다`,
    title3: `커넥션 요청 받기`,
    content3: `앱 하단 '+' 버튼의 '커넥션 코드 입력하기'로\n상대방에게 받은 커넥션 코드를 입력하면\n커넥션이 생성되어 대화가 시작됩니다`,
    content3M: `앱 하단 '+' 버튼의 '커넥션 코드 입력하기'로\n상대방에게 받은 커넥션 코드를 입력하면\n대화가 시작됩니다`,
  },
  features: {
    title: `주요 기능`,
    describe: `블록챗만의 특별한 기능으로 당신의 메시지를 완벽히 보호합니다`,
    describeM: `블록챗만의 특별한 기능으로\n당신의 메시지를 완벽히 보호합니다`,
    title1: `수정하기`,
    content1: `상대방 이름, 나와 상대방의 메시지까지\n커넥션의 모든 것을 원하는 대로\n수정할 수 있습니다`,
    title2: `컬러 및 크기 변경하기`,
    content2: `보낸 메시지와 받은 메시지의\n컬러를 자유롭게 변경할 수 있습니다\n메시지 크기는 5가지로 조절 가능합니다`,
    title3: `메시지 위치 변경하기`,
    content3: `보낸 메시지와 받은 메시지의\n위치를 자유롭게 변경할 수 있습니다`,
    // future: `음성 통화 기능은 2023년 상반기 적용 예정입니다.`,
    // futureT: `음성 통화 기능은 2023년 상반기 적용 예정입니다.`,
    // futureM: `음성 통화 기능은\n2023년 상반기 적용 예정입니다.`,
  },
  faq: {
    title: `FAQ`,
    term: `용어`,
    usage: `앱 사용`,
    privacy: `정보 보호`,
    other: `기타`,
  },
  contact: {
    title: `문의`,
    content: `블록챗에 대한 문의사항은 아래의 이메일로 연락주시기 바랍니다`,
    contentM: `블록챗에 대한 문의사항은\n아래의 이메일로 연락주시기 바랍니다`,
  },
  footer: {
    company: `블록체인랩스(주)`,
    registrationNumber: `사업자등록번호: 243-87-00909`,
    ceo: `대표자: 임병완, 박종훈`,
    contact: `contact@block-chat.net`,
    addressKR: `서울시 서초구 강남대로 311 10층 (06628)`,
    addressUS: `535 Mission St 14th Floor, San Francisco, CA 94105`,
    copyright: `© 2024. Blockchain Labs Inc. All rights reserved.`,
  },
};