import { FlexView } from '@components/Common';
import { NotiModalM } from '@components/Modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default () => {
  const koreans = [`ko`, `ko-KR`];
  const navigate = useNavigate();
  const lang = koreans.includes(navigator.language) ? `ko` : `en`;
  const [showNotiModal, setShowNotiModal] = useState<boolean>(true);

  const closeNotiModal = () => {
    setShowNotiModal(false);
    navigate(`/`);
  };

  return (
    <FlexView center fixed>
      {showNotiModal && (
        <NotiModalM closePortal={closeNotiModal} language={lang} />
      )}
    </FlexView>
  );
};
