import { FlexView, Icon, Text } from '@components/Common';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useState } from 'react';

interface AccordionProps {
  language: 'ko' | 'en';
}

export default ({ language }: AccordionProps) => {
  const deviceType = useResponsive();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <FlexView
      css={{
        overflow: `hidden`,
        borderBottom: `0.5px solid ${Colors.secondary30}`,
      }}
    >
      <FlexView
        content="between"
        css={{
          cursor: `pointer`,
          height: deviceType === `P` ? `110px` : `64px`,
        }}
        items="center"
        row
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text color={Colors.white} xLarge={deviceType === `P`} bold>
          {language === `ko`
            ? `앱 다운로드를 어떻게 하나요?`
            : `How do I download BlockChat?`}
        </Text>
        <Icon
          color={isOpen ? Colors.primary : Colors.secondary}
          name={isOpen ? `arrowUp` : `arrowDown`}
          size={24}
        />
      </FlexView>

      {isOpen && language === `ko` && (
        <FlexView
          css={{
            height: isOpen ? `auto` : 0,
            minHeight: isOpen ? `60px` : `0 !important`,
            transition: `min-height 0.5s ease-in-out`,
            padding: `0 20px`,
            marginBottom: `16px`,
          }}
        >
          <Text color={Colors.secondary70} multiLine>
            <a
              href="https://apps.apple.com/us/app/blockchat/id1635256021"
              rel="noreferrer"
              style={{
                textDecoration: `underline`,
                textUnderlinePosition: `under`,
              }}
              target="_blank"
            >
              앱 스토어
            </a>
            와&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=com.blockchainlabs.android"
              rel="noreferrer"
              style={{
                textDecoration: `underline`,
                textUnderlinePosition: `under`,
              }}
              target="_blank"
            >
              구글 플레이
            </a>
            에서 최신 버전의 앱을 다운로드 받을 수 있습니다.
            <br />
            <br />
            사용에 불편하신 점이나 개선 여부에 대한 여러분의 생각을 적극
            수렴하고 있습니다.
          </Text>
          <Text color={Colors.secondary70}>
            <Text color={Colors.primary}>contact@block-chat.net</Text>
            으로 의견 남겨주시면 감사하겠습니다.
          </Text>
        </FlexView>
      )}

      {isOpen && language === `en` && (
        <FlexView
          css={{
            height: isOpen ? `auto` : 0,
            minHeight: isOpen ? `60px` : `0 !important`,
            transition: `min-height 0.5s ease-in-out`,
            padding: `0 20px`,
            marginBottom: `16px`,
          }}
        >
          <Text color={Colors.secondary70} multiLine>
            You can download the latest version of BlockChat from&nbsp;
            <a
              href="https://apps.apple.com/us/app/blockchat/id1635256021"
              rel="noreferrer"
              style={{ textDecoration: `underline` }}
              target="_blank"
            >
              App Store
            </a>
            &nbsp;or&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=com.blockchainlabs.android"
              rel="noreferrer"
              style={{ textDecoration: `underline` }}
              target="_blank"
            >
              Google Play
            </a>
            <br />
            <br />
            If you are experiencing any difficulties or have ideas on how to
            improve BlockChat, please send us an email at&nbsp;
            <Text color={Colors.primary}>contact@block-chat.net</Text>
          </Text>
        </FlexView>
      )}
    </FlexView>
  );
};
