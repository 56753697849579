import { Anchor, FlexView, Icon, Text } from '@components/Common';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useState } from 'react';

interface AccordionProps {
  language: 'ko' | 'en';
}

export default ({ language }: AccordionProps) => {
  const deviceType = useResponsive();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <FlexView
      css={{
        overflow: `hidden`,
        borderBottom: `0.5px solid ${Colors.secondary30}`,
      }}
    >
      <FlexView
        content="between"
        css={{
          cursor: `pointer`,
          height: deviceType === `P` ? `110px` : `64px`,
        }}
        items="center"
        row
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text color={Colors.white} xLarge={deviceType === `P`} bold>
          {language === `ko`
            ? `제 개인 정보는 어떻게 보호되나요?`
            : `How is my personal data protected?`}
        </Text>
        <Icon
          color={isOpen ? Colors.primary : Colors.secondary}
          name={isOpen ? `arrowUp` : `arrowDown`}
          size={24}
        />
      </FlexView>

      {isOpen && language === `ko` && (
        <FlexView
          css={{
            height: isOpen ? `auto` : 0,
            minHeight: isOpen ? `60px` : `0 !important`,
            transition: `min-height 0.5s ease-in-out`,
            padding: `0 20px`,
            marginBottom: `16px`,
          }}
        >
          <Text color={Colors.secondary70} multiLine>
            블록챗은 어떠한 사용자의 개인정보도 수집 및 활용하지 않습니다.
            <br />
            따라서 개인 정보 유출이 원천적으로 차단됩니다. <br />
            자세한 내용은 블록챗 앱 내 &apos;개인정보 처리방침&apos;을 참고
            바랍니다.
          </Text>
        </FlexView>
      )}

      {isOpen && language === `en` && (
        <FlexView
          css={{
            height: isOpen ? `auto` : 0,
            minHeight: isOpen ? `60px` : `0 !important`,
            transition: `min-height 0.5s ease-in-out`,
            padding: `0 20px`,
            marginBottom: `16px`,
          }}
        >
          <Text color={Colors.secondary70} multiLine>
            You are never asked to enter your personal information (not even
            your phone number!) when using BlockChat.
            <br />
            There is no risk of your personal data being leaked, because we
            never collect any to begin with.
            <br />
            Please read our
            <Anchor
              href="https://broad-recess-88e.notion.site/Privacy-Policy-a71bfb3b341441a19fa28f22165c1faa"
              underline
            >
              &nbsp;Privacy Policy&nbsp;
            </Anchor>
            for more information.
          </Text>
        </FlexView>
      )}
    </FlexView>
  );
};
