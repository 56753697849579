import { PhoneRight, PhoneLeft, PhoneCenter } from '@components/Card';
import { FlexView, Text } from '@components/Common';
import languages from '@locales/languages';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useState, useEffect, useRef } from 'react';

interface SectionProps {
  lang: 'ko' | 'en';
}

export default ({ lang }: SectionProps) => {
  const deviceType = useResponsive();
  const texts = languages[lang].about;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [videoSrc, setVideoSrc] = useState([``, ``, ``, ``, ``]);

  const content1 = {
    P: texts.content1,
    T: texts.content1T,
    M: texts.content1M,
  };

  const content2 = {
    P: texts.content2,
    T: texts.content2T,
    M: texts.content2M,
  };

  const content3 = {
    P: texts.content3,
    T: texts.content3,
    M: texts.content3M,
  };

  const content4 = {
    P: texts.content4,
    T: texts.content4,
    M: texts.content4M,
  };

  useEffect(() => {
    const options = {
      root: document.getElementById(`prologue`),
      rootMargin: `0px`,
      threshold: 1,
    };

    let observer: IntersectionObserver;
    const getVideoSrc: IntersectionObserverCallback = ([isIntersecting]) => {
      if (isIntersecting) {
        setVideoSrc([
          `https://video.block-chat.net/videos/${lang}/about01.mp4`,
          `https://video.block-chat.net/videos/${lang}/about02.mp4`,
          `https://video.block-chat.net/videos/${lang}/about03.mp4`,
          `https://video.block-chat.net/videos/${lang}/about04.mp4`,
          `https://video.block-chat.net/videos/${lang}/about05.mp4`,
        ]);
        observer.unobserve(wrapperRef.current as Element);
      }
    };

    observer = new IntersectionObserver(getVideoSrc, options);
    observer.observe(wrapperRef.current as Element);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexView
      ref={wrapperRef}
      css={{
        padding: deviceType === `M` ? `60px 16px` : `100px 16px`,
      }}
      gap={deviceType === `P` ? 240 : 160}
      id="about"
      items="center"
    >
      <Text
        color={Colors.white}
        css={{
          fontSize: deviceType === `M` ? `40px` : `64px`,
          lineHeight: 1,
        }}
        bold
        center
      >
        {texts.title}
      </Text>

      <PhoneRight
        content={content1[deviceType]}
        src={videoSrc[0]}
        // subtitle={texts.subtitle1}
        title={texts.title1}
      />

      <PhoneCenter
        content={content2[deviceType]}
        src={[videoSrc[1], videoSrc[2]]}
        // subtitle={texts.subtitle2}
        title={texts.title2}
      />

      <PhoneLeft
        content={content3[deviceType]}
        src={videoSrc[3]}
        // subtitle={texts.subtitle3}
        title={texts.title3}
      />

      <PhoneRight
        content={content4[deviceType]}
        src={videoSrc[4]}
        // subtitle={texts.subtitle4}
        title={texts.title4}
      />
    </FlexView>
  );
};
