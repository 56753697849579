import HomePage from '@pages/Home';
import HomeENPage from '@pages/HomeEN';
import NoMatchPage from '@pages/NoMatch';
import NotificationPage from '@pages/Notification';

export const Home = {
  path: `/`,
  element: <HomePage />,
};

export const HomeEN = {
  path: `/en`,
  element: <HomeENPage />,
};

export const Notification = {
  path: `/notification`,
  element: <NotificationPage />,
};

export const NoMatch = {
  path: `/*`,
  element: <NoMatchPage />,
};
