import { FlexView, Text, Video } from '@components/Common';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';

interface PhoneCardProps {
  src: string;
  title: string;
  subtitle?: string;
  content: string;
}

export default ({ src, title, subtitle, content }: PhoneCardProps) => {
  const deviceType = useResponsive();

  return (
    <FlexView
      content="between"
      css={{
        maxWidth: `1040px`,
        width: `100%`,
        flexDirection: deviceType === `P` ? `row` : `column-reverse`,
      }}
      gap={deviceType === `M` ? 40 : 80}
      items="center"
    >
      <FlexView
        css={{
          width: `375px`,
          height: `758px`,
          ...(deviceType !== `P` && { width: `280px`, height: `566px` }),
        }}
      >
        <Video
          css={{ maxWidth: `100%`, objectFit: `cover` }}
          src={src}
          autoPlay
          loop
          muted
          playsInline
        />
      </FlexView>

      <FlexView>
        <Text
          color={Colors.primary}
          css={{ textAlign: deviceType === `P` ? `start` : `center` }}
          xxLarge={deviceType !== `M`}
          medium
        >
          {subtitle}
        </Text>
        <Text
          center={deviceType !== `P`}
          color={Colors.white}
          css={{
            margin: deviceType === `M` ? `8px 0 24px 0` : `16px 0 40px 0`,
            fontSize: deviceType === `M` ? `28px` : `48px`,
            lineHeight: `130%`,
            letterSpacing: deviceType === `M` ? `-0.4px` : undefined,
          }}
          bold
          multiLine
        >
          {title}
        </Text>

        <Text
          center={deviceType !== `P`}
          color={Colors.secondary70}
          css={{
            lineHeight: `160%`,
            ...(deviceType === `M` && { letterSpacing: `-0.4px` }),
          }}
          xLarge={deviceType !== `M`}
          medium
          multiLine
        >
          {content}
        </Text>
      </FlexView>
    </FlexView>
  );
};
