import * as Icons from '@assets/images/Icons';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

export type IconProps = DetailedHTMLProps<
  HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  name: string;
  color?: string;
  size?: number;
};

export default ({ name, color, size, ...props }: IconProps) => {
  const RenderIcon = () =>
    Icons[name as keyof typeof Icons](color || `#515355`);

  return (
    <span style={{ display: `inline-flex`, height: size || 24 }} {...props}>
      <RenderIcon />
    </span>
  );
};
