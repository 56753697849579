import { FlexView, Text } from '@components/Common';
import { Heading } from '@components/Text';
import languages from '@locales/languages';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useEffect, useRef } from 'react';

interface SectionProps {
  lang: 'ko' | 'en';
}

export default ({ lang }: SectionProps) => {
  const deviceType = useResponsive();
  const texts = languages[lang].prologue;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const textBoxRef = useRef<HTMLDivElement>(null);

  const content2 = {
    P: texts.content2,
    T: texts.content2T,
    M: texts.content2M,
  };

  const options = {
    root: textBoxRef.current,
    rootMargin: `0px`,
    threshold: 0.5,
  };

  const onShow: IntersectionObserverCallback = ([{ isIntersecting }]) => {
    if (isIntersecting) {
      if (isIntersecting && textBoxRef.current) {
        textBoxRef.current.style.opacity = `1`;
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onShow, options);
    observer.observe(textBoxRef.current as Element);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexView
      ref={wrapperRef}
      css={{
        padding: deviceType !== `M` ? `100px 16px` : `60px 16px`,
        minHeight: `${window.innerHeight}px`,
      }}
      id="prologue"
      center
    >
      <FlexView
        ref={textBoxRef}
        css={{ opacity: 0, transition: `opacity 2s 0.2s` }}
        center
      >
        <FlexView
          css={{
            borderRadius: `20px`,
            maxWidth: `920px`,
            width: `100%`,
            backgroundColor: `#141414E6`,
            margin: `0 auto`,
            ...(deviceType === `M` && { padding: `40px 24px` }),
            ...(deviceType === `T` && { padding: `60px 32px` }),
            ...(deviceType === `P` && { padding: `80px 60px` }),
          }}
          gap={deviceType === `M` ? 20 : 30}
          center
        >
          <Heading
            color={Colors.secondary}
            component="h1"
            css={{
              ...(deviceType === `M` && { fontSize: `16px` }),
              ...(deviceType === `T` && { fontSize: `24px` }),
              ...(deviceType === `P` && { fontSize: `32px` }),
            }}
            medium={deviceType === `M`}
            semiBold={deviceType !== `M`}
            text={deviceType === `M` ? texts.content1M : texts.content1}
            center
          />

          <Text
            color={Colors.secondary}
            css={{
              ...(deviceType === `M` && { fontSize: `16px` }),
              ...(deviceType === `T` && { fontSize: `24px` }),
              ...(deviceType === `P` && { fontSize: `32px` }),
            }}
            medium={deviceType === `M`}
            semiBold={deviceType !== `M`}
            center
            multiLine
          >
            {content2[deviceType]}
          </Text>
        </FlexView>
      </FlexView>
    </FlexView>
  );
};
