import { FlexView, Icon, Text, Video } from '@components/Common';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useState, useEffect, useRef } from 'react';

interface VideoCardProps {
  video: string;
  title: string;
  content: string;
  selected: boolean;
}

export default ({ video, title, content, selected }: VideoCardProps) => {
  const deviceType = useResponsive();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(!!selected);

  const handlePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
      videoRef.current.currentTime = 0.2;

      videoRef.current.onended = () => {
        if (videoRef.current === null) return;
        videoRef.current.currentTime = 0.2;
        setIsPlaying(false);
      };
    }
  }, []);

  return (
    <FlexView
      css={{
        border: `1px solid ${Colors.gray700}`,
        borderRadius: `20px`,
        width: deviceType === `M` ? `280px` : `400px`,
      }}
    >
      <FlexView
        content="end"
        css={{ position: `relative`, cursor: `pointer` }}
        items="end"
        onClick={handlePlay}
      >
        <FlexView>
          <Video
            ref={videoRef}
            autoPlay={selected}
            css={{
              width: deviceType === `M` ? `278px` : `398px`,
              height: deviceType === `M` ? `263px` : `373px`,
              borderTopLeftRadius: `20px`,
              borderTopRightRadius: `20px`,
              objectFit: `cover`,
              isolation: `isolate`,
            }}
            src={video}
            loop
            muted
            playsInline
          />
        </FlexView>

        <Icon
          color={Colors.primary}
          css={{
            position: `absolute`,
            zIndex: 100,
            margin: deviceType === `M` ? `0 20px 20px 0` : `0 24px 24px 0`,
          }}
          name={isPlaying ? `pause` : `play`}
          size={32}
        />
      </FlexView>

      <FlexView
        css={{
          backgroundColor: Colors.gray700,
          padding: deviceType === `M` ? `24px 20px` : `32px`,
          height: `100%`,
          borderBottomLeftRadius: `20px`,
          borderBottomRightRadius: `20px`,
        }}
        gap={deviceType === `M` ? 12 : 20}
      >
        <Text
          color={Colors.secondary}
          large={deviceType === `M`}
          xLarge={deviceType !== `M`}
          bold
          multiLine
        >
          {title}
        </Text>

        <Text color={Colors.secondary70} small={deviceType === `M`} multiLine>
          {content}
        </Text>
      </FlexView>
    </FlexView>
  );
};
