import DATALIST from '@assets/datas/faq.json';
import {
  Accordion,
  AccordionContact,
  AccordionDownload,
  AccordionPrivacy,
} from '@components/Accordion';
import { FlexView, Text } from '@components/Common';
import languages from '@locales/languages';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useState, useEffect, useRef } from 'react';

interface SectionProps {
  lang: 'ko' | 'en';
}

export default ({ lang }: SectionProps) => {
  const deviceType = useResponsive();
  const texts = languages[lang].faq;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [tabData, setTabData] = useState(DATALIST[0].data);
  const TAB_LIST = [texts.term, texts.usage, texts.privacy, texts.other];

  const selectTab = (id: number) => {
    setSelectedTab(id);
  };

  useEffect(() => {
    if (lang === `en`) {
      setTabData(DATALIST[selectedTab + 4]?.data);
    } else {
      setTabData(DATALIST[selectedTab]?.data);
    }
  }, [lang, selectedTab]);

  return (
    <FlexView
      ref={wrapperRef}
      css={{
        padding: deviceType === `M` ? `60px 0` : `100px 0`,
        minHeight: `${window.innerHeight}px`,
      }}
      gap={40}
      id="faq"
    >
      <Text
        color={Colors.white}
        css={{ fontSize: deviceType === `M` ? `40px` : `64px`, lineHeight: 1 }}
        bold
        center
      >
        {texts.title}
      </Text>

      <FlexView gap={deviceType === `M` ? 12 : 40}>
        <FlexView center row>
          {TAB_LIST.map((tab, index) => (
            <FlexView
              key={tab}
              css={{
                marginBottom: `-1px`,
                borderBottom:
                  selectedTab === index
                    ? `1px solid ${Colors.primary}`
                    : undefined,
                cursor: `pointer`,
                ...(deviceType === `P` && { width: `160px`, height: `57px` }),
                ...(deviceType === `T` && { width: `100px`, height: `46px` }),
                ...(deviceType === `M` && { width: `80px`, height: `46px` }),
              }}
              center
              onClick={() => selectTab(index)}
            >
              <Text
                bold={index === selectedTab}
                color={
                  index === selectedTab ? Colors.primary : Colors.secondary30
                }
                large={deviceType === `P`}
                small={deviceType !== `P`}
              >
                {tab}
              </Text>
            </FlexView>
          ))}
        </FlexView>

        <FlexView
          css={{
            maxWidth: deviceType === `P` ? `1280px` : `720px`,
            minHeight: `480px`,
            width: `100%`,
            margin: `0 auto`,
            padding: deviceType === `M` ? `0 20px` : `0 40px`,
          }}
        >
          {selectedTab === 1 && <AccordionDownload language={lang} />}
          {selectedTab === 2 && <AccordionPrivacy language={lang} />}
          {selectedTab === 3 && <AccordionContact language={lang} />}

          {tabData?.map(data => (
            <Accordion
              key={data.title}
              content={data.content}
              title={data.title}
            />
          ))}
        </FlexView>
      </FlexView>
    </FlexView>
  );
};
