import { FlexView, Icon, Text } from '@components/Common';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useState } from 'react';

interface AccordionProps {
  language: 'ko' | 'en';
}

export default ({ language }: AccordionProps) => {
  const deviceType = useResponsive();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <FlexView
      css={{
        overflow: `hidden`,
        borderBottom: `0.5px solid ${Colors.secondary30}`,
      }}
    >
      <FlexView
        content="between"
        css={{
          cursor: `pointer`,
          height: deviceType === `P` ? `110px` : `64px`,
        }}
        items="center"
        row
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text color={Colors.white} xLarge={deviceType === `P`} bold>
          {language === `ko`
            ? `사용 중 불편한 점이나 개선사항은 어디로 문의하면 되나요?`
            : `Help! I need help with using BlockChat.`}
        </Text>
        <Icon
          color={isOpen ? Colors.primary : Colors.secondary}
          name={isOpen ? `arrowUp` : `arrowDown`}
          size={24}
        />
      </FlexView>

      {isOpen && language === `ko` && (
        <FlexView
          css={{
            height: isOpen ? `auto` : 0,
            minHeight: isOpen ? `60px` : `0 !important`,
            transition: `min-height 0.5s ease-in-out`,
            padding: `0 20px`,
            marginBottom: `16px`,
          }}
        >
          <Text color={Colors.secondary70} multiLine>
            블록챗은 사용에 불편하신 점이나 개선 여부에 대한 사용자의 의견을
            적극 수렴하고 있습니다.
          </Text>
          <Text color={Colors.secondary70}>
            <Text color={Colors.primary}>contact@block-chat.net</Text>
            으로 의견 남겨주시면 최대한 빠른 시일 내에 답변 해드립니다.
          </Text>
        </FlexView>
      )}

      {isOpen && language === `en` && (
        <FlexView
          css={{
            height: isOpen ? `auto` : 0,
            minHeight: isOpen ? `60px` : `0 !important`,
            transition: `min-height 0.5s ease-in-out`,
            padding: `0 20px`,
            marginBottom: `16px`,
          }}
        >
          <Text color={Colors.secondary70} multiLine>
            Please email us at&nbsp;
            <Text color={Colors.primary}>contact@block-chat.net</Text>
            &nbsp;for more support.
          </Text>
        </FlexView>
      )}
    </FlexView>
  );
};
