import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface Size {
  width: number;
  height: number;
}

export const useWindowSize = (): Size => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener(`resize`, handleResize);

    handleResize();

    return () => {
      window.removeEventListener(`resize`, handleResize);
    };
  }, []);

  return windowSize;
};

export const useResponsive = () => {
  const { width } = useWindowSize();

  if (width < 600) {
    return `M`;
  }
  if (width < 1080) {
    return `T`;
  }

  return `P`;
};

export const useLanguage = () => {
  const koreans = [`ko`, `ko-KR`];
  const location = useLocation();

  const testUrl = () => {
    if (location.pathname === `/`) {
      return `ko`;
    }
    if (location.pathname === `/en`) {
      return `en`;
    }
    return koreans.includes(navigator.language) ? `ko` : `en`;
  };

  const [language, setLanguage] = useState(testUrl());

  useEffect(() => {
    setLanguage(testUrl());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // useEffect(() => {
  //   const changeUrl = () => {
  //     if (location.pathname === `/`) {
  //       setLanguage(`ko`);
  //     } else if (location.pathname === `/en`) {
  //       setLanguage(`en`);
  //     } else {
  //       setLanguage(koreans.includes(navigator.language) ? `ko` : `en`);
  //     }
  //   };

  //   window.addEventListener(`popstate`, changeUrl);

  //   return () => {
  //     window.removeEventListener(`popstate`, changeUrl);
  //   };
  // }, []);

  return language;
};
