import { Anchor, FlexView, Text, Icon } from '@components/Common';
import languages from '@locales/languages';
import { Colors } from '@styles/system';

import ModalP from './ModalP';

interface ModalProps {
  language: 'ko' | 'en';
  closePortal: () => void;
}

export default ({ language, closePortal }: ModalProps) => {
  const TEXTS = languages[language].modal;

  const wrapperCSS = {
    backgroundColor: Colors.gray700,
    borderRadius: `8px`,
    width: `160px`,
    minHeight: `64px`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  };

  return (
    <ModalP closePortal={closePortal} height={680} width={680} close>
      <FlexView
        css={{
          paddingTop: `8px`,
          overflowY: `auto`,
          scrollbarWidth: `none`,
          '::-webkit-scrollbar': { display: `none` },
        }}
        gap={60}
        fill
      >
        <FlexView gap={24}>
          <Icon color={Colors.primary} name="logo" size={28} />

          <Text color={Colors.secondary70} multiLine>
            {TEXTS.downloadGuide}
          </Text>
        </FlexView>

        <FlexView content="center" gap={24} row>
          <Anchor
            css={wrapperCSS}
            href="https://apps.apple.com/us/app/blockchat/id1635256021"
            referrerPolicy="no-referrer"
            target="_blank"
          >
            <Icon color={Colors.white} name="appStore" size={24} />
          </Anchor>

          <Anchor
            css={wrapperCSS}
            href="https://play.google.com/store/apps/details?id=com.blockchainlabs.android"
            referrerPolicy="no-referrer"
            target="_blank"
          >
            <Icon color={Colors.white} name="googlePlay" size={20} />
          </Anchor>
        </FlexView>

        <FlexView gap={24}>
          <Text color={Colors.secondary} medium multiLine>
            {TEXTS.notice}
          </Text>
          <FlexView gap={24}>
            <FlexView gap={12} row>
              <Text color={Colors.secondary70} css={{ lineHeight: `150%` }}>
                1.
              </Text>
              <Text color={Colors.secondary70} multiLine>
                {TEXTS.notice1}
              </Text>
            </FlexView>
            {/* <FlexView gap={12} row>
              <Text color={Colors.secondary70} css={{ lineHeight: `150%` }}>
                2.
              </Text>
              <Text color={Colors.secondary70} multiLine>
                {TEXTS.notice2}
              </Text>
            </FlexView> */}
          </FlexView>
        </FlexView>

        {language === `ko` && (
          <FlexView gap={4}>
            <Text color={Colors.secondary70}>{TEXTS.advice1}</Text>

            <Text color={Colors.secondary70}>
              <Text color={Colors.primary}>{`${TEXTS.email} `}</Text>
              {TEXTS.advice2}
            </Text>
          </FlexView>
        )}

        {language === `en` && (
          <FlexView gap={4}>
            <Text color={Colors.secondary70}>{TEXTS.advice1}</Text>

            <Text color={Colors.secondary70}>
              {`${TEXTS.advice2} `}
              <Text color={Colors.primary}>{TEXTS.email}</Text>
            </Text>
          </FlexView>
        )}
      </FlexView>
    </ModalP>
  );
};
