import { Button, FlexView, Icon, Text } from '@components/Common';
import { MenuModal, MenuModalT } from '@components/Modal';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useState, useEffect, CSSProperties } from 'react';
import { useLocation } from 'react-router-dom';

const headerMCSS: CSSProperties = {
  position: `fixed`,
  height: `60px`,
  top: 0,
  left: 0,
  right: 0,
  padding: `20px 24px 16px 24px`,
  backgroundColor: Colors.black,
  zIndex: 900,
};

interface HeaderProps {
  language: 'ko' | 'en';
}

export default ({ language }: HeaderProps) => {
  const location = useLocation();
  const deviceType = useResponsive();
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuT, setShowMenuT] = useState(false);
  const [showLogo, setShowLogo] = useState(false);

  const goTop = () => {
    window.scrollTo({ top: 0, behavior: `smooth` });
  };

  const openMenuModal = () => {
    setShowMenu(true);
  };

  const openMenuModalT = () => {
    setShowMenuT(true);
  };

  const closeMenuModal = () => {
    setShowMenu(false);
  };

  const closeMenuModalT = () => {
    setShowMenuT(false);
  };

  useEffect(() => {
    if (deviceType === `M`) closeMenuModal();
    else if (deviceType === `T`) closeMenuModalT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const onScroll = () => {
      const nowPosition = window.scrollY;
      const section1 = document.getElementById(`prologue`)?.offsetTop as number;

      if (nowPosition < section1 - 10) {
        setShowLogo(false);
      } else {
        setShowLogo(true);
      }
    };

    window.addEventListener(`scroll`, onScroll);

    return () => window.removeEventListener(`scroll`, onScroll);
  }, []);

  return (
    <header style={headerMCSS}>
      <FlexView content="between" items="center" row>
        <FlexView>
          {showLogo && (
            <Button
              aria-label="home"
              css={{ width: `123.21px`, height: `15px` }}
              onClick={goTop}
            >
              <Text color="transparent">Header Logo</Text>
              <Icon
                color={Colors.primary}
                css={{ position: `absolute` }}
                name="logo"
                size={15}
              />
            </Button>
          )}
        </FlexView>

        <Button
          aria-label="menu"
          onClick={deviceType === `T` ? openMenuModalT : openMenuModal}
        >
          <Icon color={Colors.secondary} name="hamburger" size={24} />
        </Button>

        {showMenu && (
          <MenuModal closePortal={closeMenuModal} language={language} />
        )}
        {showMenuT && (
          <MenuModalT closePortal={closeMenuModalT} language={language} />
        )}
      </FlexView>
    </header>
  );
};
