import { Button, FlexView, Link, Text } from '@components/Common';
import languages from '@locales/languages';
import { Colors } from '@styles/system';
import { useState, useEffect } from 'react';

import ModalT from './ModalT';

interface ModalProps {
  language: 'ko' | 'en';
  closePortal: () => void;
}

export default ({ language, closePortal }: ModalProps) => {
  const TEXTS = languages[language].menu;
  const [selectedMenu, setSelectedMenu] = useState<string>(`intro`);

  const ID_LIST = [
    `intro`,
    `prologue`,
    `about`,
    `howtouse`,
    `features`,
    `faq`,
    `contact`,
  ];
  const MENU_LIST = [
    TEXTS.about,
    TEXTS.howToUse,
    TEXTS.features,
    TEXTS.faq,
    TEXTS.contact,
  ];

  const selectMenu = (id: number) => {
    window.scrollTo({
      top: (document.getElementById(ID_LIST[id])?.offsetTop as number) - 10,
      behavior: `smooth`,
    });
    setSelectedMenu(ID_LIST[id]);

    closePortal();
  };

  useEffect(() => {
    const nowPosition = window.scrollY;
    const section1 = document.getElementById(`prologue`)?.offsetTop as number;
    const section2 = document.getElementById(`about`)?.offsetTop as number;
    const section3 = document.getElementById(`howtouse`)?.offsetTop as number;
    const section4 = document.getElementById(`features`)?.offsetTop as number;
    const section5 = document.getElementById(`faq`)?.offsetTop as number;
    const section6 = document.getElementById(`contact`)?.offsetTop as number;

    if (nowPosition < section1 - 10) {
      setSelectedMenu(`intro`);
    } else if (nowPosition < section2 - 10) {
      setSelectedMenu(`prologue`);
    } else if (nowPosition < section3 - 10) {
      setSelectedMenu(`about`);
    } else if (nowPosition < section4 - 10) {
      setSelectedMenu(`howtouse`);
    } else if (nowPosition < section5 - 10) {
      setSelectedMenu(`features`);
    } else if (nowPosition < section6 - 10) {
      setSelectedMenu(`faq`);
    } else if (nowPosition >= section6 - 10) {
      setSelectedMenu(`contact`);
    }
  }, []);

  return (
    <ModalT closePortal={closePortal} close>
      <FlexView
        content="between"
        css={{ width: `328px`, marginTop: `100px` }}
        fill
      >
        <FlexView css={{ padding: `0 8px` }} gap={40}>
          {MENU_LIST?.map((item, index) => (
            <Button
              key={item}
              css={{
                textAlign: `left`,
                height: `30px`,
                padding: `0 8px`,
                backgroundColor:
                  ID_LIST.indexOf(selectedMenu) === index + 2
                    ? Colors.secondary10
                    : undefined,
              }}
              onClick={() => selectMenu(index + 2)}
            >
              <Text
                bold={ID_LIST.indexOf(selectedMenu) === index + 2}
                color={
                  ID_LIST.indexOf(selectedMenu) === index + 2
                    ? Colors.secondary
                    : Colors.secondary30
                }
                css={{ transition: `color 0.5s`, width: `100%` }}
                xLarge
              >
                {item}
              </Text>
            </Button>
          ))}
        </FlexView>

        <FlexView
          css={{
            padding: `40px 0 22px 0`,
            borderTop: `1px solid ${Colors.secondary10}`,
          }}
          row
        >
          <Link
            aria-label="KOR"
            css={{
              width: `64px`,
              lineHeight: `36px`,
              textAlign: `center`,
              color: language === `ko` ? Colors.secondary : Colors.secondary30,
            }}
            to="/"
          >
            KOR
          </Link>

          <Link
            aria-label="ENG"
            css={{
              width: `64px`,
              lineHeight: `36px`,
              textAlign: `center`,
              color: language === `en` ? Colors.secondary : Colors.secondary30,
            }}
            to="/en"
          >
            ENG
          </Link>
        </FlexView>
      </FlexView>
    </ModalT>
  );
};
