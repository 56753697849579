import Header from '@components/Layout/Header';
import HeaderM from '@components/Layout/HeaderM';
import { useResponsive } from '@utils/hooks';
import { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

export default () => {
  const koreans = [`ko`, `ko-KR`];
  const location = useLocation();
  const deviceType = useResponsive();

  const [language, setLanguage] = useState<'ko' | 'en'>(
    koreans.includes(navigator.language) ? `ko` : `en`,
  );

  const headers = {
    P: <Header language={language} />,
    T: <HeaderM language={language} />,
    M: <HeaderM language={language} />,
  };

  useEffect(() => {
    if (location.pathname === `/`) {
      setLanguage(`ko`);
    } else {
      setLanguage(`en`);
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route element={headers[deviceType]} path="/" />
      <Route element={headers[deviceType]} path="/en" />
    </Routes>
  );
};
