import {
  Anchor,
  Button,
  FlexView,
  Icon,
  Text,
  Video,
} from '@components/Common';
import { NotiModalM } from '@components/Modal';
import languages from '@locales/languages';
import { Colors } from '@styles/system';
import React, { useState, useEffect, useRef } from 'react';

interface SectionProps {
  lang: 'ko' | 'en';
}

export default ({ lang }: SectionProps) => {
  const TEXTS = languages[lang].intro;
  const wrapRef = useRef<HTMLDivElement>(null);
  const hideRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showNotiModal, setShowNotiModal] = useState<boolean>(false);

  const openNotiModal = () => {
    setShowNotiModal(true);
  };

  const closeNotiModal = () => {
    setShowNotiModal(false);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
      videoRef.current.play();

      videoRef.current.onended = () => {
        if (videoRef.current === null) return;
        videoRef.current.currentTime = 5.07;
        videoRef.current.play();
      };
    }
  }, []);

  return (
    <FlexView
      ref={wrapRef}
      css={{ minHeight: `calc(${window.innerHeight - 60}px)` }}
      id="intro"
      center
    >
      <FlexView
        content="center"
        css={{
          width: `100%`,
        }}
        fill
      >
        <Video
          ref={videoRef}
          css={{
            maxWidth: `100%`,
            objectFit: `cover`,
          }}
          muted
          playsInline
        >
          <source
            src={`https://video.block-chat.net/videos/${lang}/introM.mp4`}
            type="video/mp4"
          />
        </Video>
      </FlexView>

      <FlexView
        ref={hideRef}
        className={!videoRef.current?.paused ? `fade-in` : undefined}
        css={{
          marginBottom: `24px`,
          minHeight: lang === `ko` ? `166px` : `186px`,
        }}
        gap={24}
        items="center"
      >
        <FlexView gap={8}>
          <Button
            aria-label="download"
            css={{
              width: `316px`,
              height: `47px`,
              border: `1px solid ${Colors.primary}`,
              borderRadius: `8px`,
            }}
            onClick={openNotiModal}
          >
            <Text color={Colors.primary} semiBold>
              {TEXTS.download}
            </Text>
          </Button>

          <Anchor
            aria-label="Blockchain Labs"
            css={{
              width: `316px`,
              height: `47px`,
              backgroundColor: Colors.secondary10,
              borderRadius: `8px`,
              color: `transparent`,
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
            }}
            href={
              lang === `ko` ? `https://bc-labs.net` : `https://bc-labs.net/en`
            }
            rel="noopener noreferrer"
            target="_blank"
          >
            blockchain labs
            <Icon
              color={Colors.secondary80}
              css={{ position: `absolute` }}
              name="company"
              size={14}
            />
          </Anchor>
        </FlexView>

        <Text color={Colors.secondary80} multiLine={lang === `en`} center small>
          {TEXTS.subtitle1M}
          <Anchor
            href={lang === `en` ? `https://coov.io/en` : `https://coov.io`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Icon
              css={{
                margin: lang === `ko` ? `0 6px` : `0 0 0 6px`,
              }}
              name="coov"
              size={13}
            />
          </Anchor>
          {lang === `en` && `, `}
          <br />
          {TEXTS.subtitle2}
        </Text>
      </FlexView>

      {showNotiModal && (
        <NotiModalM closePortal={closeNotiModal} language={lang} />
      )}
    </FlexView>
  );
};
