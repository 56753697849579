import { Button, FlexView, Icon } from '@components/Common';
import { CSSObject } from '@emotion/react';
import { Colors } from '@styles/system';
import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

interface ModalProps {
  children: ReactNode;
  closePortal: () => void;
  color?: string;
  close?: boolean;
}

export default ({ children, closePortal, color, close }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const container = document.getElementById(`root-modal`);

  const backgoundCSS: CSSObject = {
    position: `fixed`,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    backgroundColor: `#0000004D`,
  };

  const contentCSS: CSSObject = {
    padding: `18px 16px`,
    backgroundColor: color || Colors.gray800,
    position: `fixed`,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 10200,
  };

  useEffect(() => {
    setTimeout(() => {
      if (modalRef.current) {
        modalRef.current.style.transform = `translateX(0)`;
      }
    }, 200);

    document.body.style.overflow = `hidden`;

    return () => {
      document.body.style.overflow = `unset`;
    };
  }, []);

  return container
    ? createPortal(
        <FlexView css={backgoundCSS} role="presentation" onClick={closePortal}>
          <FlexView
            ref={modalRef}
            css={{
              ...contentCSS,
              transform: `translateX(360px)`,
              transition: `transform 0.5s 0.1s ease-in-out`,
            }}
            gap={40}
          >
            <FlexView content="end" row>
              {close && (
                <Button aria-label="close" onClick={closePortal}>
                  <Icon color={Colors.secondary} name="close" size={24} />
                </Button>
              )}
            </FlexView>
            {children}
          </FlexView>
        </FlexView>,
        container,
      )
    : null;
};
