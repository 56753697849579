import { FlexView, Text, Video } from '@components/Common';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useLocation } from 'react-router-dom';

interface PhoneCardProps {
  src: string[];
  title: string;
  subtitle?: string;
  content: string;
}

export default ({ src, title, subtitle, content }: PhoneCardProps) => {
  const location = useLocation();
  const deviceType = useResponsive();
  const language = location.pathname === `/` ? `ko` : `en`;

  return (
    <FlexView
      css={{
        maxWidth: `1040px`,
        width: `100%`,
      }}
      gap={deviceType === `M` ? 40 : 60}
      items="center"
    >
      <FlexView>
        <Text color={Colors.primary} xxLarge={deviceType !== `M`} center medium>
          {subtitle}
        </Text>
        <Text
          color={Colors.white}
          css={{
            margin: deviceType === `M` ? `8px 0 24px 0` : `16px 0 40px 0`,
            fontSize: deviceType === `M` ? `28px` : `48px`,
            lineHeight: `130%`,
            letterSpacing: language === `en` ? `-0.1px` : undefined,
          }}
          bold
          center
          multiLine
        >
          {title}
        </Text>
        <Text
          color={Colors.secondary70}
          css={{
            lineHeight: `160%`,
            letterSpacing: language === `en` ? `-0.3px` : undefined,
          }}
          xLarge={deviceType !== `M`}
          center
          medium
          multiLine
        >
          {content}
        </Text>
      </FlexView>

      <FlexView
        css={{
          width: `100%`,
          overflowX: `auto`,
          whiteSpace: `nowrap`,
          scrollbarHeight: `none`,
          '::-webkit-scrollbar': { display: `none` },
        }}
      >
        <FlexView
          css={{
            margin: deviceType === `P` ? `40px auto 0 auto` : `0 auto`,
          }}
          gap={deviceType === `P` ? 110 : 16}
          row
        >
          <FlexView
            css={{
              width: `375px`,
              height: `758px`,
              ...(deviceType !== `P` && { width: `280px`, height: `566px` }),
            }}
          >
            <Video
              css={{ maxWidth: `100%`, objectFit: `cover` }}
              src={src[0]}
              autoPlay
              loop
              muted
              playsInline
            />
          </FlexView>
          <FlexView
            css={{
              width: `375px`,
              height: `758px`,
              ...(deviceType !== `P` && { width: `280px`, height: `566px` }),
            }}
          >
            <Video
              css={{ maxWidth: `100%`, objectFit: `cover` }}
              src={src[1]}
              autoPlay
              loop
              muted
              playsInline
            />
          </FlexView>
        </FlexView>
      </FlexView>
    </FlexView>
  );
};
