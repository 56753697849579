import { Anchor, FlexView, Icon, Text } from '@components/Common';
import { CSSObject } from '@emotion/react';
import languages from '@locales/languages';
import { Colors } from '@styles/system';
import React from 'react';

interface FooterProps {
  language: 'ko' | 'en';
}

const anchorCSS: CSSObject = {
  color: `transparent`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
};

export default ({ language }: FooterProps) => {
  const TEXTS = languages[language].footer;

  const LINK_LIST_KO = [
    {
      name: `youtube`,
      url: `https://www.youtube.com/channel/UCJtRwutl4ee8jQ_OfHkwa5Q`,
    },
    {
      name: `instagram`,
      url: `https://www.instagram.com/blockchainlabs.official`,
    },
    {
      name: `facebook`,
      url: `https://www.facebook.com/BlockChat-%EB%B8%94%EB%A1%9D%EC%B1%97-109548188471590`,
    },
  ];

  const LINK_LIST_EN = [
    {
      name: `youtube`,
      url: `https://www.youtube.com/channel/UCJtRwutl4ee8jQ_OfHkwa5Q`,
    },
    {
      name: `facebook`,
      url: `https://www.facebook.com/BlockChat-%EB%B8%94%EB%A1%9D%EC%B1%97-109548188471590`,
    },
  ];

  const hrCSS: CSSObject = {
    backgroundColor: Colors.secondary30,
    width: `1px`,
    height: `15px`,
    margin: `0 12px`,
  };

  return (
    <footer>
      <FlexView
        css={{ padding: `60px 40px`, backgroundColor: Colors.gray800 }}
        gap={32}
      >
        <Anchor
          aria-label="Blockchain Labs"
          css={{
            width: `211px`,
            height: `28px`,
            ...anchorCSS,
          }}
          href={
            language === `ko` ? `https://bc-labs.net` : `https://bc-labs.net/en`
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          Blockchain Labs
          <Icon
            color={Colors.white}
            css={{ position: `absolute` }}
            name="company"
            size={24}
          />
        </Anchor>

        {language === `ko` && (
          <FlexView css={{ marginTop: `8px` }} gap={16} items="start">
            <FlexView items="center" row>
              <Text color={Colors.secondary50} small>
                {TEXTS.company}
              </Text>
              <FlexView css={hrCSS} />
              <Text color={Colors.secondary50} small>
                {TEXTS.ceo}
              </Text>
            </FlexView>

            <FlexView items="center" row>
              <Text color={Colors.secondary50} small>
                {TEXTS.registrationNumber}
              </Text>
              <FlexView css={hrCSS} />
              <Text color={Colors.secondary50} small>
                {TEXTS.contact}
              </Text>
            </FlexView>

            <Text color={Colors.secondary50} small>
              {TEXTS.addressKR}
            </Text>

            <Text color={Colors.secondary50} small>
              {TEXTS.addressUS}
            </Text>
          </FlexView>
        )}

        {language === `en` && (
          <FlexView css={{ marginTop: `8px` }} gap={16} items="start">
            <FlexView items="center" row>
              <Text color={Colors.secondary50} small>
                {TEXTS.company}
              </Text>
              <FlexView css={hrCSS} />
              <Text color={Colors.secondary50} small>
                {TEXTS.contact}
              </Text>
            </FlexView>

            <Text color={Colors.secondary50} small>
              {TEXTS.addressUS}
            </Text>

            <Text color={Colors.secondary50} small>
              {TEXTS.addressKR}
            </Text>
          </FlexView>
        )}

        <FlexView gap={16} items="center">
          <FlexView
            content="end"
            css={{ margin: `8px 0` }}
            gap={4}
            items="center"
            row
          >
            {language === `ko` &&
              LINK_LIST_KO.map(item => (
                <Anchor
                  key={item.name}
                  aria-label={`BlockChat ${item.name}`}
                  css={{ ...anchorCSS, width: `40px`, height: `40px` }}
                  href={item.url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {item.name}
                  <Icon color={Colors.secondary50} name={item.name} size={40} />
                </Anchor>
              ))}

            {language === `en` &&
              LINK_LIST_EN.map(item => (
                <Anchor
                  key={item.name}
                  aria-label={`BlockChat ${item.name}`}
                  css={{ ...anchorCSS, width: `40px`, height: `40px` }}
                  href={item.url}
                  rel="noopener noreferrer"
                >
                  {item.name}
                  <Icon color={Colors.secondary50} name={item.name} size={40} />
                </Anchor>
              ))}
          </FlexView>
        </FlexView>

        <Text color={Colors.secondary50} center small>
          {TEXTS.copyright}
        </Text>
      </FlexView>
    </footer>
  );
};
