import FooterRouter from '@routes/FooterRouter';
import HeaderRouter from '@routes/HeaderRouter';
import MainRouter from '@routes/MainRouter';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const changeSeoSetting = (lang: 'ko' | 'en') => {
      const TITLES = { ko: `블록챗`, en: `BlockChat` };
      const DESCRIPTIONS = {
        ko: `세계 최초 서버 없는 블록체인 메신저 블록챗`,
        en: `No Server Only Blockchain, BlockChat`,
      };

      document.documentElement.setAttribute(`lang`, lang);
      document.title = TITLES[lang];
      document
        .querySelector(`meta[name="description"]`)
        ?.setAttribute(`content`, DESCRIPTIONS[lang]);
    };

    if (location.pathname === `/`) {
      changeSeoSetting(`ko`);
    } else if (location.pathname === `/en`) {
      changeSeoSetting(`en`);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.search !== ``) navigate(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderRouter />
      <MainRouter />
      <FooterRouter />
    </>
  );
};
