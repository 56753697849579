import Footer from '@components/Layout/Footer';
import FooterM from '@components/Layout/FooterM';
import FooterT from '@components/Layout/FooterT';
import { useResponsive } from '@utils/hooks';
import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

export default () => {
  const koreans = [`ko`, `ko-KR`];
  const location = useLocation();
  const deviceType = useResponsive();
  const [language, setLanguage] = useState<'ko' | 'en'>(
    koreans.includes(navigator.language) ? `ko` : `en`,
  );

  const footers = {
    P: <Footer language={language} />,
    T: <FooterT language={language} />,
    M: <FooterM language={language} />,
  };

  useEffect(() => {
    if (location.pathname === `/`) {
      setLanguage(`ko`);
    } else {
      setLanguage(`en`);
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route element={footers[deviceType]} path="/" />
      <Route element={footers[deviceType]} path="/en" />
    </Routes>
  );
};
