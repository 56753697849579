import { FlexView, Icon, Link, Text } from '@components/Common';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';

export default () => {
  const koreans = [`ko`, `ko-KR`];
  const deviceType = useResponsive();
  const language = koreans.includes(navigator.language) ? `ko` : `en`;

  const desktopCSS = {
    height: `100px`,
    padding: `40px 80px 20px 80px`,
  };

  const mobileCSS = {
    height: `60px`,
    padding: `20px 24px 16px 24px`,
  };

  return (
    <FlexView fixed>
      <FlexView css={deviceType === `P` ? desktopCSS : mobileCSS}>
        <Link
          aria-label="home"
          css={{ width: `fit-content`, height: `24px`, margin: `auto 0` }}
          to="/"
        >
          <Icon
            color={Colors.primary}
            name="logo"
            size={deviceType === `P` ? 24 : 15}
          />
        </Link>
      </FlexView>

      <FlexView css={{ padding: `0 24px` }} gap={60} center fill>
        <Text
          color={Colors.secondary}
          css={{ fontSize: deviceType === `P` ? `48px` : `28px` }}
          bold
          center
        >
          {language === `ko` ? `페이지를 찾을 수 없습니다` : `Page Not Found`}
        </Text>

        <Link to="/">
          <Text
            color={Colors.secondary80}
            css={{
              textDecoration: `underline`,
              textUnderlinePosition: `under`,
            }}
            large
          >
            {language === `ko` ? `홈으로` : `Home`}
          </Text>
        </Link>
      </FlexView>
    </FlexView>
  );
};
