import { CSSObject } from '@emotion/react';

export const fontSizes: { [key: string]: CSSObject } = {
  xxSmall: { fontSize: `0.625rem` }, // 10px
  xSmall: { fontSize: `0.75rem` }, // 12px
  small: { fontSize: `0.875rem` }, // 14px
  normal: { fontSize: `1rem` }, // 16px
  large: { fontSize: `1.25rem` }, // 20px
  xLarge: { fontSize: `1.5rem` }, // 24px
  xxLarge: { fontSize: `1.75rem` }, // 28px
  xxxLarge: { fontSize: `2rem` }, // 32px
};

export const FontSizes = {
  xxSmall: `0.625rem`, // 10px
  xSmall: `0.75rem`, // 12px
  small: `0.875rem`, // 14px
  normal: `1rem`, // 16px
  large: `1.25rem`, // 20px
  xLarge: `1.5rem`, // 24px
  xxLarge: `1.75rem`, // 28px
  xxxLarge: `2rem`, // 32pt
};

export const fontWeights: { [key: string]: CSSObject } = {
  thin: { fontWeight: 100 },
  extraLight: { fontWeight: 200 },
  light: { fontWeight: 300 },
  regular: { fontWeight: 400 },
  medium: { fontWeight: 500 },
  semiBold: { fontWeight: 600 },
  bold: { fontWeight: 700 },
  extraBold: { fontWeight: 800 },
  black: { fontWeight: 900 },
};

export const FontWeights = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const Colors = {
  primary: `#1DCEC9`,
  primary80: `#1DCEC9CC`,
  secondary: `#F4F4F4`,
  secondary80: `#F4F4F4CC`,
  secondary70: `#F4F4F4B3`,
  secondary60: `#F4F4F499`,
  secondary50: `#F4F4F480`,
  secondary30: `#F4F4F44D`,
  secondary20: `#F4F4F433`,
  secondary10: `#F4F4F41A`,
  white: `#FFFFFF`,
  black: `#000000`,
  gray900: `#0D0D0D`,
  gray800: `#1B1B1B`,
  gray700: `#1F1F1F`,
  gray: `#BEBFC0`,
};
