import { VideoCard } from '@components/Card';
import { FlexView, Text } from '@components/Common';
import languages from '@locales/languages';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useState, useEffect, useRef } from 'react';

interface SectionProps {
  lang: 'ko' | 'en';
}

export default ({ lang }: SectionProps) => {
  const deviceType = useResponsive();
  const TEXTS = languages[lang].features;
  const wrapperRef = useRef<HTMLDivElement>(null);
  // const futureText = {
  //   P: TEXTS.future,
  //   T: TEXTS.futureT,
  //   M: TEXTS.futureM,
  // };

  const [cardInfo, setCardInfo] = useState([
    {
      title: TEXTS.title1,
      content: TEXTS.content1,
      video: ``,
    },
    {
      title: TEXTS.title2,
      content: TEXTS.content2,
      video: ``,
    },
    {
      title: TEXTS.title3,
      content: TEXTS.content3,
      video: ``,
    },
  ]);

  useEffect(() => {
    const options = {
      root: document.getElementById(`about`),
      rootMargin: `0px`,
      threshold: 1,
    };

    let observer: IntersectionObserver;
    const getVideoSrc: IntersectionObserverCallback = ([isIntersecting]) => {
      if (isIntersecting) {
        cardInfo[0].video = `https://video.block-chat.net/videos/${lang}/features01.mp4`;
        cardInfo[1].video = `https://video.block-chat.net/videos/${lang}/features02.mp4`;
        cardInfo[2].video = `https://video.block-chat.net/videos/${lang}/features03.mp4`;
        setCardInfo([...cardInfo]);

        observer.unobserve(wrapperRef.current as Element);
      }
    };

    observer = new IntersectionObserver(getVideoSrc, options);
    observer.observe(wrapperRef.current as Element);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexView
      ref={wrapperRef}
      css={{
        padding: deviceType !== `M` ? `100px 24px` : `60px 24px`,
        minHeight: `${window.innerHeight}px`,
      }}
      id="features"
    >
      <FlexView
        css={{
          maxWidth: `1400px`,
          width: `100%`,
          margin: `0 auto`,
          overflowX: `hidden`,
        }}
      >
        <Text
          color={Colors.white}
          css={{
            fontSize: deviceType === `M` ? `40px` : `64px`,
            lineHeight: 1,
          }}
          bold
          center
        >
          {TEXTS.title}
        </Text>

        <Text
          color={Colors.secondary70}
          css={{ margin: `24px 0 60px 0` }}
          large={deviceType !== `M`}
          center
          multiLine
        >
          {deviceType === `P` ? TEXTS.describe : TEXTS.describeM}
        </Text>

        <FlexView
          css={{
            overflowX: `auto`,
            whiteSpace: `nowrap`,
            scrollbarHeight: `none`,
            '::-webkit-scrollbar': { display: `none` },
          }}
        >
          <FlexView css={{ margin: `0 auto` }} gap={24} row>
            {cardInfo?.map((item, index) => (
              <VideoCard
                key={item.title}
                content={item.content}
                selected={index === 0}
                title={item.title}
                video={item.video}
              />
            ))}
          </FlexView>
        </FlexView>

        <Text
          color={Colors.secondary50}
          css={{ marginTop: `32px` }}
          large={deviceType !== `M`}
          center
          medium
          multiLine
        >
          {/* {deviceType ==='M' ? TEXTS.futureM : TEXTS.future} */}
          {/* {futureText[deviceType]} */}
        </Text>
      </FlexView>
    </FlexView>
  );
};
