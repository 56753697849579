import { FlexView } from '@components/Common';
import {
  About,
  Contact,
  HowToUse,
  Features,
  IntroDesktop,
  IntroMobile,
  FAQ,
  Prologue,
} from '@components/Section';
import { useResponsive } from '@utils/hooks';
import { useRef } from 'react';

export default () => {
  const deviceType = useResponsive();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const Intro = {
    P: <IntroDesktop lang="ko" />,
    T: <IntroMobile lang="ko" />,
    M: <IntroMobile lang="ko" />,
  };

  return (
    <FlexView
      ref={wrapperRef}
      css={{ marginTop: deviceType === `P` ? `100px` : `60px` }}
      gap={16}
    >
      {Intro[deviceType]}
      <Prologue lang="ko" />
      <About lang="ko" />
      <HowToUse lang="ko" />
      <Features lang="ko" />
      <FAQ lang="ko" />
      <Contact lang="ko" />
    </FlexView>
  );
};
