import { Button, FlexView, Text } from '@components/Common';
import languages from '@locales/languages';
import { Colors } from '@styles/system';
import { useResponsive } from '@utils/hooks';
import { useRef } from 'react';

interface SectionProps {
  lang: 'ko' | 'en';
}

export default ({ lang }: SectionProps) => {
  const deviceType = useResponsive();
  const texts = languages[lang].contact;
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <FlexView
      ref={wrapperRef}
      css={{
        padding: deviceType === `M` ? `60px 0` : `100px 0`,
        minHeight: `${window.innerHeight}px`,
      }}
      id="contact"
      center
    >
      <Text
        color={Colors.white}
        css={{ fontSize: deviceType === `M` ? `40px` : `64px`, lineHeight: 1 }}
        bold
        center
      >
        {texts.title}
      </Text>

      <Text
        color={Colors.secondary70}
        css={{
          margin: deviceType === `P` ? `40px 0 120px 0` : `24px 0 60px 0`,
        }}
        large={deviceType !== `M`}
        multiLine={deviceType === `M`}
        center
        medium
      >
        {deviceType === `M` ? texts.contentM : texts.content}
      </Text>

      <Button
        aria-label="contact"
        css={{
          border: `1px solid ${Colors.primary}`,
          borderRadius: `8px`,
          width: `308px`,
          height: deviceType === `M` ? `47px` : `60px`,
          userSelect: `auto`,
        }}
        onClick={() => {
          window.open(`mailto:contact@block-chat.net`);
        }}
      >
        <Text color={Colors.primary} large={deviceType !== `M`} fill medium>
          contact@block-chat.net
        </Text>
      </Button>
    </FlexView>
  );
};
